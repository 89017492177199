"use strict";
window.addEventListener('load', event => {
    let appTitle = document.getElementById('app-title');
    if (!appTitle) {
        return;
    }
    if (window.location.origin.includes('localhost')) {
        appTitle.innerText = `Development - Jill's Office`;
    }
    if (window.location.origin.includes('app.staging.jillsoffice.com')) {
        appTitle.innerText = `Staging - Jill's Office`;
    }
    if (window.location.origin.includes('beta.app.staging.jillsoffice.com')) {
        appTitle.innerText = `Beta-Staging - Jill's Office`;
    }
    if (window.location.origin.includes('beta.app.jillsoffice.com')) {
        appTitle.innerText = `Beta-Prod - Jill's Office`;
    }
});
